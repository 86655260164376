<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="profile-switch" slot="component">
      <p class="profile-switch-pin-title">{{ $t("set new pin") }}</p>
      <p class="profile-switch-pin-desc">{{ $t("Please enter OTP sent to")}} </p>
        <p class="user-mobile-number"> {{subscriberDetails.data.mobileno}}
          </p> 
          <p class="user-email"> {{subscriberDetails.data.email}}
          </p> 
      <div class="profile-switch-pin">
        <form @submit.prevent="submitSwitchProfile">
          <div class="form-group">
            <div class="error-container" v-if="formError">
              <p class="error">{{ formError }}</p>
            </div>
            <div dir="ltr" class="form-control profile-switch-pin-box">
              <div id="pininputs" class="profile-switch-pin-box-inputs">
                <input
                  v-for="(item, index) in numberOfOtpBlocks"
                  :key="index"
                  class="pin"
                  type="text"
                  v-model="userOtp[item - 1]"
                  maxlength="1"
                />
              </div>
            </div>

            <div dir="ltr"  class="form-control profile-switch-pin-box">
                <p class="profile-switch-pin-box-label">{{ $t("set pin") }}</p>
              <div id="userpininputs" class="profile-switch-pin-box-inputs">
                <input
                  v-for="(item, index) in numberOfPinBlocks"
                  :key="index"
                  class="pin"
                  type="text"
                  v-model="userPin[item - 1]"
                  maxlength="1"
                />
              </div>
            </div>
          </div>
         
          <div>
            <button class="button-primary">
               <span class="btn-txt-color">{{ $t("reset") }}</span>
            </button>
          </div>
        </form>
      </div>

      <br />

      <div v-if="isLoading"><Loading /></div>
    </div>
  </Popup>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { _providerId } from "@/provider-config.js";
import { mapActions, mapGetters, mapMutations } from "vuex";

import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import { showSnackBar } from '../../utilities';

export default {
  props: {
    closePopupEvent: {
      type: Function,
    },

    profile: {
      type: Object,
    },
  },

  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      userPin: [],
      userOtp: [],
      profilePin: null,
      profileOtp: null,
      formError: null,
      providerUniqueId: _providerId,
      numberOfOtpBlocks: 6,
      numberOfPinBlocks: 4,
      isLoading: false,
      subscriberDetails : null,
    };
  },
created(){


},
  watch: {
    formError() {
      setTimeout(() => {
        this.formError = null;
      }, 2000);
    },
  },

  computed: {
    ...mapGetters(["getPlanDeeplinkPath", "subscriberid"])
  },

  mounted() {
    this.setupPin();
    this.userSetupPin();
    this.subscriberDetails = localStorage.getItem("subscriberDetails")
        ? JSON.parse(localStorage.getItem("subscriberDetails"))
        : null;
       
  },

  methods: {
    ...mapActions(["switchProfile", "createNewProfilePin", "fcmMessagingSubscribeToTopic"]),

    ...mapMutations(["setProfileid"]),

    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
  },
   userSetupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("userpininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
  },

    submitSwitchProfile() {
      this.isLoading = true;

      if (!(this.userPin.length === this.numberOfPinBlocks )) {
        this.formError = this.$t("Please enter the PIN");
        this.isLoading = false;
        
      }

if (!( this.userOtp.length === this.numberOfOtpBlocks)) {
        this.formError = this.$t("Please enter the OTP");
        this.isLoading = false;
        return;
      }

      const createNewProfilePinPayload = {
        pin: this.userPin.join(''),
        otp: this.userOtp.join('')
      };

      this.createNewProfilePin(createNewProfilePinPayload)
        .then((response) => {
          if (!response.data.reason) {
            this.isLoading = false;
            showSnackBar(this.$t("PIN changed successfully"));
            this.$emit("closeSetNewPinPopup");  
          } else {
            showSnackBar(this.$t(response.data.reason));

            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    validateOtp(pin) {
      this.formError = null;
      if (!pin) {
        this.formError = "Please enter pin";
        return false;
      }
      return true;
    },

    closePopup() {
      console.log("CLOSE POPUP");

      this.$emit("closeSetNewPinPopup");
    },
  },

  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.profile-switch {
  padding: 3.6rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  &-pin-title {
    text-align: center;
    color: $clr-light-gd4;
    font-size: 1.4rem;
    color: #c1c1c1;
    font-family: $font-regular;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 1.2rem;
  }
  &-pin-desc {
    font-size: 14px;
    color: #5C6168;
    font-family:  $font-regular;
    text-align: center;
    width: auto;
    margin: 10px auto 21px;
  }
  &-pin-box {
    display: flex;
    padding-bottom: 20px;
    label {
      font-size: 0.8rem;
    }
    &-inputs {
      margin-left: auto;
      display: inherit;
      .pin {
        width: 47px;
        margin-right: 5px;
        text-align: center;
      }
    }
    &-label {
        font-size: 16px;
        color: #5C6168;
        margin: auto;
        font-family:  $font-regular;
    }
  }
  &-pin-forgot {
    color: #F7F9FC;
    font-size: 14px;
    font-family:  $font-regular;
    margin: 10px auto;
    text-align: center;
    cursor: pointer;
  }
}
.user-mobile-number{
  color: #5C6168;
  // position: absolute;
  // right:-90px;
  // top: 112px;
  text-align: center;
    // width: 65%;
    // margin: 10px auto 21px;
     font-size: 14px;
    color: #5C6168;
    font-family:  $font-regular;
  margin-top: -20px;
  width:100%;

}
.user-email{
  color: #5C6168;
  // position: absolute;
  // right: 80px;
  // top: 130px;
  text-align: center;
   
    margin: 10px auto 21px;
    font-size :15px 

}
  // media only screen and (max-width : 576px)
@media only screen and (max-width: 1366px) { 
  .profile-switch {
    &-pin-title {
      font-size: 0.9rem;
    }
      &-pin-box {
    label {
      font-size: 0.8rem;
    }
    &-inputs {
      margin-left: auto;
      // width:100%;
      display: inline-flex;
      .pin {
        width: 29.1px;
        margin-right:4.5px;
        text-align: center;
      
      }
    }
    &-label {
        font-size: 16px;
        color: #5C6168;
        font-family:  $font-regular;
        margin-right: 4.5px;
        width: 39%;
    }
  }
  }
  .user-mobile-number{
    font-size:0.9rem;
  }
  .user-email{
    font-size:0.9rem ;
     width: auto;
		word-break: break-all;
     
  }

}
</style>
